import { useFragment as useApolloFragment } from '@apollo/client';

function useFragment({ fragment, id }) {
  const { data, complete, missing } = useApolloFragment({
    fragment,
    fragmentName: fragment.definitions[0].name.value,
    from: {
      __typename: fragment.definitions[0].typeCondition.name.value,
      id,
    },
  });

  return {
    data: Object.keys(data).length ? data : null,
    complete,
    missing,
  };
}

export default useFragment;
